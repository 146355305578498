@import "ai-tokens";
//@import "~bootstrap/dist/css/bootstrap.css";
//@import "~bootstrap-icons/font/bootstrap-icons.css";
// @import "~@coreui/coreui-pro/scss/coreui";
//@import "~bootstrap-grid/dist/grid.css";


html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  // Default body font styles
  font-family: $ai-font-default;
  font-style: normal;
  font-weight: 400;
  font-size: $ai-size-font-default;

  // Default font color
  color: $ai-color-text-dark;

  // Default background color
  background-color: $ai-color-background;

  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $ai-color-primary-brand;
}

article.centered {

  padding-top: $ai-spacing-large;

  & > * {
    box-sizing: border-box;
  }

  .centered-content {
    max-width: 600px;
    min-width: 480px;
    width: 40%;
    position: relative;
  }

  & > section,
  & > footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  & > section {
    padding: 0 $ai-spacing-large;
  }
}

.form-input-container {
  margin-top: $ai-spacing-default;
  margin-bottom: $ai-spacing-default;
}

@media (max-width: 840px) {
  article.centered {

    .centered-content {
      min-width: auto;
      width: 100%;
    }

    & > section {
      padding: 0 $ai-spacing-tiny;
    }
  }
}

.rotate {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}

// ============================================================================
h2.dark {
  font-size: $ai-size-font-headline;
  line-height: $ai-size-font-headline;
  margin-top: 0;
  font-weight: 900;
}

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';

//html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

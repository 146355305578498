//
@use "@angular/material" as mat;
// For HOW to theming angular-material see this guide: https://material.angular.io/guide/theming
//
// import material theming to customize

//
// Additionally using - AI style tokens - for customization
//
@import "ai-tokens";

//
// Custom color palette
//
// A core feature of material-design is the color system: https://material.io/design/color/the-color-system.html#color-usage-and-palettes
// U can generate custom pallets by using this tool: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
//
// Emetriq blue color palette
$ai-emetriq-blue: (
  50 : #ffffff,
  100 : $ai-color-primary-brand,
  200 : $ai-color-primary-brand,
  300 : $ai-color-primary-brand,
  400 : $ai-color-primary-brand,
  500 : $ai-color-primary-brand,
  600 : $ai-color-primary-brand,
  700 : $ai-color-primary-brand,
  800 : $ai-color-primary-brand,
  900 : $ai-color-primary-brand,
  A100 : #ffffff,
  A200 : #d3eef9,
  A400 : #3fc3f4,
  A700 : #6dbbe5,
  contrast: (
    50 : $ai-color-text-dark,
    100 : $ai-color-text-dark,
    200 : $ai-color-text-dark,
    300 : $ai-color-text-dark,
    400 : $ai-color-text-dark,
    500 : $ai-color-text-light,
    600 : $ai-color-text-light,
    700 : $ai-color-text-light,
    800 : $ai-color-text-light,
    900 : $ai-color-text-light,
    A100 : $ai-color-text-dark,
    A200 : $ai-color-text-dark,
    A400 : $ai-color-text-dark,
    A700 : $ai-color-text-dark,
  )
);

$ai-red: (
  50: $ai-color-alert-red,
  100: $ai-color-alert-red,
  200: $ai-color-alert-red,
  300: $ai-color-alert-red,
  400: $ai-color-alert-red,
  500: $ai-color-alert-red,
  600: $ai-color-alert-red,
  700: $ai-color-alert-red,
  800: $ai-color-alert-red,
  900: $ai-color-alert-red,
  A100: #ffcbbd,
  A200: #ffa993,
  A400: #ff6b47,
  A700: #e64420,
  contrast: (
    50: $ai-color-text-dark,
    100: $ai-color-text-dark,
    200: $ai-color-text-dark,
    300: $ai-color-text-dark,
    400: $ai-color-text-dark,
    500: $ai-color-text-light,
    600: $ai-color-text-light,
    700: $ai-color-text-light,
    800: $ai-color-text-light,
    900: $ai-color-text-light,
    A100: $ai-color-text-dark,
    A200: $ai-color-text-light,
    A400: $ai-color-text-light,
    A700: $ai-color-text-light,
  )
);

//
// Custom foreground colors
//
// To apply custom colors inside material-components, we redefined the "mat-light-theme-foreground" configuration
// (Search for "$mat-light-theme-foreground" in '~@angular/material/theming' for details)
//
@function ai-mat-light-theme-foreground($color) {
  @return (
    base: $color,
    divider: $ai-color-background-contrast,
    dividers: $ai-color-background-contrast,
    disabled: rgba(black, 0.38),
    disabled-button: rgba($color, 0.26),
    disabled-text: rgba(black, 0.38),
    elevation: black,
    hint-text: rgba(black, 0.38),
    secondary-text: $color,
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: $color,
    slider-min: rgba($color, 0.87),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
  );
}

$ai-light-foreground: ai-mat-light-theme-foreground($ai-color-text-dark);

//
// Custom Typography
//
// See: https://material.angular.io/guide/typography
//
$custom-typography: mat.define-typography-config(
  $font-family: #{$ai-font-default},
  $body-1: mat.define-typography-level($ai-size-font-default, $ai-size-font-line-height-default, 400),
  $body-2: mat.define-typography-level($ai-size-font-default, $ai-size-font-line-height-default, 600),
  $caption: mat.define-typography-level($ai-size-font-default, $ai-size-font-line-height-default, 400),
);
// Apply the custom typography
// @include mat.core($custom-typography);

//
// Custom AI Theme
//
$emetriq-app-primary: mat.define-palette($ai-emetriq-blue);
$emetriq-app-accent: mat.define-palette(mat.$cyan-palette, A200, A100, A400);
$emetriq-app-warn: mat.define-palette($ai-red);
$emetriq-app-theme: mat.define-light-theme($emetriq-app-primary, $emetriq-app-accent, $emetriq-app-warn);

// Apply/merge custom foreground colors into AI custom theme
// $my-app-theme-custom: $emetriq-app-theme;
$my-app-theme-custom: map-merge($emetriq-app-theme, (foreground: $ai-light-foreground));

// Enable the custom hell
/*
 _______________________
|                       |
|        _______        |
|       || ON  ||       |
|       ||_____||       |
|       |/    /||       |
|       /    / ||       |
|      /____/ /-'       |
|      |____|/          |
|                       |
`-----------------------'
*/
@include mat.all-component-themes($my-app-theme-custom);


//
// Global customized material components
//

// all mat buttons gets a custom 0 radius (material default: 4px)
a.mat-button-base,
button.mat-button-base {
  border-radius: 0;
}

.mat-form-field {

  // all mat-form-fields 100% width
  width: 100%;

  // Manually custom color for underline because the material one has a transparency value
  // see #ai-mat-light-theme-foreground.divider
  &.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $ai-color-background-contrast;
  }
}


// size definition material icons
@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

.mat-icon.mat-icon-s {
  @include md-icon-size(1.5rem);
}

.mat-icon.mat-icon-m {
  @include md-icon-size(2rem);
}

.mat-icon.mat-icon-l {
  @include md-icon-size(2.5rem);
}

.mat-icon.mat-icon-xl {
  @include md-icon-size(3rem);
}

.mat-icon.mat-icon-xxl {
  @include md-icon-size(5rem);
}

.ai-snackbar-success {
  background-color: $ai-color-alert-green;
  color: $ai-color-text-light;

  .mat-button-wrapper {
    color: $ai-color-text-light;
  }
}

.ai-snackbar-error {
  background-color: $ai-color-alert-red;
  color: $ai-color-text-light;

  .mat-button-wrapper {
    color: $ai-color-text-light;
  }
}

// Apply a white background input frame without a border
.ai-mat-checkbox-inverted {

  // white background
  .mat-checkbox-inner-container {
    background-color: $ai-color-background;
    border-radius: $ai-border-radius-default;
  }

  // no border
  .mat-checkbox-inner-container .mat-checkbox-frame {
    border: 0;
  }

}
/* Spacing */
/* Colors */
/* Fonts */
/* Font Size */
/* Component dimensions */
/* For table column width only due to bootstrap grid using flex */
/* percentage width of a single column */
/* Shadows */
/* Border radius */
/* Assets */
/* Mixins */
/* Can be uses for elements that have to be sticky below the header. */
/* custom styles */
h1.ai-h1-light {
  line-height: 1.2;
  font-size: 3rem;
  font-weight: 900;
  font-family: "Titillium Web", sans-serif;
  color: #ffffff;
}

h2.ai-h2 {
  font-size: 2.25rem;
  color: #66696a;
  font-weight: 400;
  line-height: 1.2em;
}

h3.ai-h3 {
  margin: 0;
  font-size: 2.25rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #66696a;
  font-weight: 700;
  font-family: "Titillium Web", sans-serif;
}

h4.ai-h4, p.ai-p {
  margin: 0;
}

.ai-page-header {
  position: relative;
  background-color: #00748b;
  margin-bottom: calc(2.5rem + 2rem);
}
.ai-page-header.ai-h1-light {
  margin-top: 0;
  line-height: 8rem;
}
.ai-page-header::after {
  content: "";
  background-color: #e7ff1c;
  position: absolute;
  bottom: -2.5rem;
  right: 0;
  width: 60%;
  height: 2.5rem;
}

.ai-button {
  padding: 0 1.5rem 0;
  line-height: 2.5rem;
  border-bottom: 0.5rem solid #e7ff1c;
  background: none;
  cursor: pointer;
}
.ai-button:hover {
  color: #17ace0;
}
.ai-button:active {
  color: #66696a;
  background: #e7ff1c;
}

.ai-tab-group {
  width: 100%;
}
.ai-tab-group .mat-tab-label {
  font-size: 1.125rem;
  margin-bottom: -0.5rem;
  height: 4rem;
  user-select: none;
}
.ai-tab-group .mat-tab-label.mat-tab-label-active {
  padding: 0 1rem;
  border: 0.5rem solid #e7ff1c;
  border-bottom: none;
  background-color: #ffffff;
  opacity: 1;
}
.ai-tab-group .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
  margin-top: -0.5rem;
}
.ai-tab-group .mat-tab-label:not(.mat-tab-label-active) {
  border-bottom: 0.5rem solid #e7ff1c;
}
.ai-tab-group .mat-tab-label:not(.mat-tab-label-active) .mat-tab-label-content {
  margin-bottom: -0.5rem;
}
.ai-tab-group .mat-tab-label-container:before {
  content: "";
  background-color: #e7ff1c;
  width: 1rem;
  height: 0.5rem;
  align-self: flex-end;
}
.ai-tab-group .mat-ink-bar {
  display: none;
}
.ai-tab-group .mat-tab-labels {
  border-bottom: 0.5rem solid #E7FF1C;
}
.ai-tab-group .mat-tab-header {
  border-bottom: none;
}
.ai-tab-group .mat-tab-body-content {
  padding: 1.5rem 1rem;
}

.ai-button-mat-icon {
  cursor: pointer;
}
.ai-button-mat-icon .material-icons,
.ai-button-mat-icon .mat-icon {
  vertical-align: bottom;
  margin-right: 1rem;
}

.ai-mat-icon-left-adjust {
  margin-left: -4px;
}

.ai-action-button {
  color: #00748b;
  border-radius: 0;
  height: 4rem;
  width: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ai-action-button:hover {
  background-color: #00748b;
  color: white;
  transition: background-color 0.2s ease-in;
}
.ai-action-button.mat-button-disabled:hover {
  color: #adadad;
}
.ai-action-button mat-icon {
  display: flex;
}

.ai-input {
  font-size: 1rem;
  color: #66696a;
}

.ai-input-no-border {
  border: 0;
}
.ai-input-no-border:focus {
  outline: none;
}

.ai-table-hover tbody tr {
  transition: background-color 0.1s ease-in;
}
.ai-table-hover tbody tr:hover {
  background-color: #F0F0F0;
}

.ai-reports-table {
  width: 100%;
  margin-bottom: 4rem;
}
.ai-reports-table thead th {
  top: calc(5rem + 3.25rem);
  position: sticky;
  position: -webkit-sticky;
  background-color: #ffffff;
  z-index: 1000;
}
.ai-reports-table .mat-header-cell,
.ai-reports-table .mat-cell {
  outline: none;
  padding: 0 0.5rem;
}

.ai-dynamic-ellipsis {
  display: flex;
  width: 100%;
}
.ai-dynamic-ellipsis > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.ai-pointer {
  cursor: pointer;
}

.ai-display-none {
  display: none;
}

.ai-text-primary-brand {
  color: #00748b;
}

.ai-text-error {
  color: #bf3013;
}

.ai-text-warning {
  color: #BE8B13;
}

.ai-text-dark {
  color: #66696a;
}

.ai-text-label {
  font-size: 1.125rem;
}

.ai-list {
  padding-inline-start: 1.5rem;
}
.ai-list li::marker {
  color: #00748b;
}

input.ai-timeframe, .ai-timeframe {
  color: #00748b;
}

.ai-full-width {
  width: 100%;
}

.ai-menu-button {
  font-size: 1.125rem;
  text-decoration: none;
  min-width: 100px;
  text-align: center;
  position: relative;
  padding-bottom: 12px;
  color: #66696a;
}
.ai-menu-button:active, .ai-menu-button.selected:active {
  color: #66696a;
}
.ai-menu-button:hover:not(:active) {
  color: #17ace0;
}
.ai-menu-button:after {
  color: #17ace0;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50px;
  height: 8px;
  transition: 0.3s ease-in;
  width: 0;
}
.ai-menu-button.selected {
  color: #17ace0;
}
.ai-menu-button.selected:after, .ai-menu-button:active:after {
  background-color: #e7ff1c;
  width: 100%;
  left: 0;
}

.mat-stroked-button.emq-button-link,
.mat-stroked-button.emq-button-link-inverted,
.mat-flat-button.emq-button-action,
.mat-flat-button.emq-button-action-inverted {
  text-transform: uppercase;
  border-radius: 0;
  height: 54px;
  line-height: 50px;
  transition: all 0.2s ease-in;
}

.mat-stroked-button.emq-button-link,
.mat-stroked-button.emq-button-link-inverted {
  font-weight: 800;
  font-size: 1.5rem;
  color: #3f3f3f;
  padding: 0 0.5rem;
  line-height: 50px;
  border-radius: 0;
  border: none;
  border-bottom: 0.25rem solid #e7ff1c;
}
.mat-stroked-button.emq-button-link:hover,
.mat-stroked-button.emq-button-link-inverted:hover {
  border-color: #f0f0f0;
  color: #17ace0;
}
.mat-stroked-button.emq-button-link:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button.emq-button-link-inverted:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0;
  background: transparent;
}

.mat-stroked-button.mat-primary.emq-button-link,
.mat-stroked-button.mat-primary.emq-button-link-inverted {
  color: #3f3f3f;
}
.mat-stroked-button.mat-primary.emq-button-link:hover,
.mat-stroked-button.mat-primary.emq-button-link-inverted:hover {
  color: #17ace0;
}
.mat-stroked-button.mat-primary.emq-button-link:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button.mat-primary.emq-button-link-inverted:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0;
  background: transparent;
}

.mat-stroked-button.emq-button-link-inverted,
.mat-stroked-button.mat-primary.emq-button-link-inverted {
  color: #ffffff;
}
.mat-stroked-button.emq-button-link-inverted:hover,
.mat-stroked-button.mat-primary.emq-button-link-inverted:hover {
  color: #17ace0;
}

.mat-flat-button.emq-button-action,
.mat-flat-button.emq-button-action-inverted,
.mat-flat-button.mat-primary.emq-button-action-inverted {
  font-weight: 600;
  font-size: 1.25rem;
  background-color: #e7ff1c;
  color: #3f3f3f;
}
.mat-flat-button.emq-button-action:hover,
.mat-flat-button.emq-button-action-inverted:hover,
.mat-flat-button.mat-primary.emq-button-action-inverted:hover {
  background-color: #17ace0;
  color: #ffffff;
}

.mat-flat-button.emq-button-action-inverted,
.mat-flat-button.mat-primary.emq-button-action-inverted {
  background-color: #17ace0;
  color: #ffffff;
}
.mat-flat-button.emq-button-action-inverted:hover,
.mat-flat-button.mat-primary.emq-button-action-inverted:hover {
  background-color: #00748b;
  color: #ffffff;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #66696a;
  background-color: #ffffff;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #00748b;
}

article.centered {
  padding-top: 1.5rem;
}
article.centered > * {
  box-sizing: border-box;
}
article.centered .centered-content {
  max-width: 600px;
  min-width: 480px;
  width: 40%;
  position: relative;
}
article.centered > section, article.centered > footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
article.centered > section {
  padding: 0 1.5rem;
}

.form-input-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 840px) {
  article.centered .centered-content {
    min-width: auto;
    width: 100%;
  }
  article.centered > section {
    padding: 0 0.25rem;
  }
}
.rotate {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}
h2.dark {
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 0;
  font-weight: 900;
}

/* Importing Bootstrap SCSS file. */
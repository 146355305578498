/* Spacing */
$ai-spacing-tiny: .25rem; // rem equivalent to 4px
$ai-spacing-small: .5rem; // rem equivalent to 8px
$ai-spacing-default: 1rem; // rem equivalent to 16px
$ai-spacing-large: 1.5rem; // rem equivalent to 24px
$ai-spacing-xlarge: 2rem; // rem equivalent to 32px
$ai-spacing-xxlarge: 3rem; // rem equivalent to 48px
$ai-spacing-xxxlarge: 4rem; // rem equivalent to 48px

$ai-breakpoint-mobile-portrait: 20rem; // rem equivalent to 320px
$ai-breakpoint-mobile-landscape: 30rem; // rem equivalent to 480px
$ai-breakpoint-tablet-portrait: 40rem; // rem equivalent to 640px
$ai-breakpoint-tablet-landscape: 60rem; // rem equivalent to 960px
$ai-breakpoint-desktop-regular: 64rem; // rem equivalent to 1024px
$ai-breakpoint-desktop-large: 80rem; // rem equivalent to 1280px

/* Colors */
$ai-color-primary-brand: #00748b;
$ai-color-secondary-brand: #17ace0;
$ai-color-secondary-brand-lighter: #6dbbe5;
$ai-color-accent: #e7ff1c;

$ai-color-background: #ffffff;
$ai-color-background-grey-light: #f6f6f6;
$ai-color-background-grey: #adadad;
$ai-color-background-grey-dark: #66696a;
$ai-color-background-contrast: #F0F0F0;

$ai-color-border: #f0f0f0;
$ai-color-border-dark: #808080;

$ai-color-text-default: #3f3f3f;
$ai-color-text-dark: #66696a;
$ai-color-text-light: #ffffff;
$ai-color-text-black: #000000;

$ai-color-text-disabled: #adadad;

$ai-color-alert-green: #089961;
$ai-color-alert-red: #bf3013;
$ai-color-alert-yellow: #BE8B13;

/* Fonts */
$ai-font-fallback: sans-serif;
$ai-font-default: "Titillium Web", $ai-font-fallback;

/* Font Size */
$ai-size-font-fineprint: .75rem;
$ai-size-font-data-label: .875rem; // rem equivalent to 14px
$ai-size-font-default: 1rem;
$ai-size-font-line-height-default: 1.5rem; // rem equivalent to 24px
$ai-size-font-label: 1.125rem; // rem equivalent to 18px
$ai-size-font-large: 1.25rem; // rem equivalent to 20px
$ai-size-font-caption: 1.5rem; // rem equivalent to 24px
$ai-size-font-subheadline: 2.25rem; // rem equivalent to 36px
$ai-size-font-headline: 3rem; // rem equivalent to 48px

$ai-font-weight-extralight: 200;
$ai-font-weight-light: 300;
$ai-font-weight-regular: 400;
$ai-font-weight-semibold: 600;
$ai-font-weight-bold: 700;
$ai-font-weight-black: 900;

/* Component dimensions */
$ai-height-app-header: 5rem;
$ai-height-plan-banner: 3.25rem;
$ai-height-search-bar: 3rem;
$ai-height-breadcrump: 2rem;

/* For table column width only due to bootstrap grid using flex */
$ai-grid-column-count: 12;
/* percentage width of a single column */
$ai-grid-column-width: calc(100% / #{$ai-grid-column-count});

/* Shadows */
$ai-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);

/* Border radius */
$ai-border-radius-default: 2px;

/* Assets */
$ai-asset-logo-audience-insights-base: "/assets/img/audience-insights-logo.svg";
$ai-asset-logo-emetriq-inverted: "/assets/img/emetriq-logo-inverted.svg";

/* Mixins */
@mixin ai-sticky-table-header($top:0, $z: 1000) {
  top: $top;
  position: sticky;
  position: -webkit-sticky;
  background-color: $ai-color-background;
  z-index: $z;
}

/* Can be uses for elements that have to be sticky below the header. */
@mixin ai-sticky-to-header($offset: 0rem) {
  position: sticky;
  // there is a calculation error in positioning, therefore we need to subtract a extra pixel
  top: calc(#{$ai-height-app-header} + #{$ai-height-breadcrump} + #{$offset} - 1px);
}

// TODO: extract classes and ... into separate file later
/* custom styles */
h1.ai-h1-light {
  line-height: 1.2;
  font-size: $ai-size-font-headline;
  font-weight: 900;
  font-family: $ai-font-default;
  color: $ai-color-text-light;
}

h2.ai-h2 {
  font-size: $ai-size-font-subheadline;
  color: $ai-color-text-dark;
  font-weight: 400;
  line-height: 1.2em;
}

h3.ai-h3 {
  margin: 0;
  font-size: $ai-size-font-subheadline;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: $ai-color-text-dark;
  font-weight: 700;
  font-family: $ai-font-default;
}

h4.ai-h4, p.ai-p {
  margin: 0;
}

// Page header style
.ai-page-header {
  $height-headline-decoration-bar: 2.5rem;
  $height-headline-primary-color: 8rem;
  $decoration-bar-width: 60%;

  position: relative; // relative because the bottom bar must be absolute in combination
  background-color: $ai-color-primary-brand;
  margin-bottom: calc(#{$height-headline-decoration-bar} + #{$ai-spacing-xlarge});

  // should only one h1 exist if so move this together @freddy
  &.ai-h1-light {
    margin-top: 0;
    line-height: $height-headline-primary-color;
  }

  // this creates the yellow bottom bar
  &::after {
    content: '';
    background-color: $ai-color-accent;
    position: absolute;
    bottom: -$height-headline-decoration-bar;
    right: 0;
    width: $decoration-bar-width;
    height: $height-headline-decoration-bar;
  }
}

// Applies button styles to a <div>Name</div>
.ai-button {
  padding: 0 1.5rem 0;
  line-height: 2.5rem;
  border-bottom: 0.5rem solid $ai-color-accent;
  background: none;
  cursor: pointer;

  &:hover {
    color: $ai-color-secondary-brand;
  }

  &:active {
    color: $ai-color-text-dark;
    background: $ai-color-accent;
  }
}

// yellow bordered tabs <mat-tab-group class="ai-tab-group">
.ai-tab-group {
  width: 100%;

  .mat-tab-label {
    font-size: $ai-size-font-label;
    margin-bottom: -$ai-spacing-small;
    height: $ai-spacing-xxxlarge;
    user-select: none;

    &.mat-tab-label-active {
      padding: 0 $ai-spacing-default;
      border: $ai-spacing-small solid $ai-color-accent;
      border-bottom: none;
      background-color: $ai-color-background;
      opacity: 1;

      .mat-tab-label-content {
        margin-top: -$ai-spacing-small;
      }
    }

    &:not(.mat-tab-label-active) {
      border-bottom: $ai-spacing-small solid $ai-color-accent;

      .mat-tab-label-content {
        margin-bottom: -$ai-spacing-small;
      }
    }
  }

  .mat-tab-label-container:before {
    content: '';
    background-color: $ai-color-accent;
    width: $ai-spacing-default;
    height: $ai-spacing-small;
    align-self: flex-end;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-labels {
    border-bottom: $ai-spacing-small solid #E7FF1C;
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-body-content {
    padding: $ai-spacing-large $ai-spacing-default;
  }
}

// Applies styles to a <a><mat-icon>icon</mat-icon>Name</a>
.ai-button-mat-icon {
  cursor: pointer;

  .material-icons,
  .mat-icon {
    vertical-align: bottom;
    margin-right: $ai-spacing-default;
  }
}

// Used to adjust the unnatural indent of the mat-icon>icon</mat-icon>
.ai-mat-icon-left-adjust {
  margin-left: -4px;
}

// Styles a button, which can contain a centered mat-icon
.ai-action-button {
  color: $ai-color-primary-brand;
  border-radius: 0;
  height: 4rem;
  width: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $ai-color-primary-brand;
    color: white;
    transition: background-color 0.2s ease-in;
  }

  &.mat-button-disabled:hover {
    color: $ai-color-text-disabled;
  }

  // try to center the mat-icon
  mat-icon {
    display: flex;
  }
}

// input with default font-size and default color
.ai-input {
  font-size: $ai-size-font-default;
  color: $ai-color-text-dark;
}

// removes border + outline from native input
.ai-input-no-border {
  border: 0;

  &:focus {
    outline: none;
  }
}

// Add .ai-table-hover to enable a hover state on table rows within a <tbody>.
.ai-table-hover {
  tbody tr {
    transition: background-color .1s ease-in;

    &:hover {
      background-color: $ai-color-background-contrast;
    }
  }
}

// custom styles for report tables
.ai-reports-table {
  width: 100%;
  margin-bottom: $ai-spacing-xxxlarge;

  // sticky header - depending on other components
  thead th {
    @include ai-sticky-table-header(calc(#{$ai-height-app-header} + #{$ai-height-plan-banner}));
  }

  .mat-header-cell,
  .mat-cell {
    outline: none;
    padding: 0 $ai-spacing-small;
  }
}

// dynamic text ellipsis container mainly used for table cell text
// when used in table cells, the parent cell needs a pixel based 'max-width' to work properly
// <td style="max-width: 170px"><div class="ai-dynamic-ellipsis"><div>Lorem Ipsum very long text</div></div></td>

@mixin ellipsis {
  display: flex;
  width: 100%;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.ai-dynamic-ellipsis {
  @include ellipsis;
}

// adds a pointer as cursor
.ai-pointer {
  cursor: pointer;
}

// hide the element
.ai-display-none {
  display: none;
}

// primary brand color
.ai-text-primary-brand {
  color: $ai-color-primary-brand;
}

.ai-text-error {
  color: $ai-color-alert-red;
}

.ai-text-warning {
  color: $ai-color-alert-yellow;
}

.ai-text-dark {
  color: $ai-color-text-dark;
}

// label
.ai-text-label {
  font-size: $ai-size-font-label;
}

// custom styled list
.ai-list {

  li::marker {
    color: $ai-color-primary-brand;
  }

  padding-inline-start: $ai-spacing-large;

  //list-style: none;
  //li::before {
  //  color: $ai-color-primary-brand;
  //  content: "\2022"; __<<ngThemingMigrationEscapedComment13>>__
  //  font-weight: bold; __<<ngThemingMigrationEscapedComment14>>__
  //  display: inline-block; __<<ngThemingMigrationEscapedComment15>>__
  //  width: $ai-spacing-default; __<<ngThemingMigrationEscapedComment16>>__
  //  margin-left: -$ai-spacing-default; __<<ngThemingMigrationEscapedComment17>>__
  //}
}

input.ai-timeframe, .ai-timeframe {
  color: $ai-color-primary-brand;
}

// 100% width
.ai-full-width {
  width: 100%;
}

.ai-menu-button {
  font-size: $ai-size-font-label;
  text-decoration: none;
  min-width: 100px;
  text-align: center;

  // relative positioning to use the :after bar absolute position
  position: relative;

  // lift up the text above the text
  padding-bottom: 12px;
  color: $ai-color-text-dark;

  // on mouse click
  &:active,
  &.selected:active {
    color: $ai-color-text-dark;
  }

  // on hover but not while clicked
  &:hover:not(:active) {
    color: $ai-color-secondary-brand;
  }

  // pseudo element as yellow bottom border
  &:after {
    color: $ai-color-secondary-brand;
    content: ''; // fake content to see the bar

    // positioning the "active" bar
    position: absolute;
    bottom: 0;
    left: 50px;

    height: 8px;

    transition: .3s ease-in;
    width: 0; // zero width when not active

  }

  // when routerLink is active
  &.selected {
    color: $ai-color-secondary-brand;
  }

  // when routerLink is active or link is currently clicked (mousedown)
  // show yellow bottom border
  &.selected,
  &:active {
    &:after {
      background-color: $ai-color-accent;
      width: 100%;
      left: 0;
    }
  }
}

.mat-stroked-button.emq-button-link,
.mat-stroked-button.emq-button-link-inverted,
.mat-flat-button.emq-button-action,
.mat-flat-button.emq-button-action-inverted {
  text-transform: uppercase;
  border-radius: 0;
  height: 54px;
  line-height: 50px;
  transition: all .2s ease-in;
}

.mat-stroked-button.emq-button-link,
.mat-stroked-button.emq-button-link-inverted {
  font-weight: 800;
  font-size: $ai-size-font-caption;
  color: $ai-color-text-default;
  padding: 0 $ai-spacing-small;
  line-height: 50px;
  border-radius: 0;
  border: none;
  border-bottom: $ai-spacing-tiny solid $ai-color-accent;

  &:hover {
    border-color: $ai-color-border;
    color: $ai-color-secondary-brand;

    &:not(.mat-button-disabled) .mat-button-focus-overlay {
      opacity: 0;
      background: transparent;
    }
  }
}

.mat-stroked-button.mat-primary.emq-button-link,
.mat-stroked-button.mat-primary.emq-button-link-inverted {
  color: $ai-color-text-default;

  &:hover {
    color: $ai-color-secondary-brand;

    &:not(.mat-button-disabled) .mat-button-focus-overlay {
      opacity: 0;
      background: transparent;
    }
  }
}

.mat-stroked-button.emq-button-link-inverted,
.mat-stroked-button.mat-primary.emq-button-link-inverted, {
  color: $ai-color-text-light;

  &:hover {
    color: $ai-color-secondary-brand;
  }
}

.mat-flat-button.emq-button-action,
.mat-flat-button.emq-button-action-inverted,
.mat-flat-button.mat-primary.emq-button-action-inverted {
  font-weight: 600;
  font-size: $ai-size-font-large;
  background-color: $ai-color-accent;
  color: $ai-color-text-default;

  &:hover {
    background-color: $ai-color-secondary-brand;
    color: $ai-color-text-light;
  }
}

.mat-flat-button.emq-button-action-inverted,
.mat-flat-button.mat-primary.emq-button-action-inverted {
  background-color: $ai-color-secondary-brand;
  color: $ai-color-text-light;

  &:hover {
    background-color: $ai-color-primary-brand;
    color: $ai-color-text-light;
  }
}
